<script>
export default {
  props: ['profit'],
  data() {
    return {};
  },
  computed: {
    baseValue() {
      return this.profit && this.profit.baseValue
    },
    baseQuote() {
      return this.profit && this.profit.baseQuote
    },
    percentage() {
      return this.profit && this.profit.percentage
    }
  },

  methods: {
    formatPrice(value) {
      let val = (value/1).toFixed(2);

      if (this.percentage < 0) {
        val = val * -1;
      }

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    formatPercentage(value) {
        if (this.percentage < 0) {
          value = value * -1
        }

        return value;
    }
  },
};
</script>
<template>
  <h6 class="fs-13 mb-1" :class="{ 'text-danger': this.baseValue < 0 , 'text-success': this.baseValue >= 0 }">
    {{ formatPrice(this.baseValue) }} $
    (<i :class="{ 'ri-arrow-down-s-fill': this.percentage < 0, 'ri-arrow-up-s-fill': this.percentage >= 0 }"></i>{{ formatPercentage(this.percentage) }}%)
  </h6>
  <!-- Sidebar -->
</template>
