<script>
import "flatpickr/dist/flatpickr.css";

import ProfitService from "@/services/profit-service";
import { layoutComputed } from "@/state/helpers";
import moment from "moment";

export default {
  name: 'MonthlyProfitChart',
  data() {
    return {
      profitChart: {
        series: [
          {
            name: this.$t('t-monthly-income'),
            type: 'column',
            data: []
          },
          {
            name: this.$t('t-accumulated-income'),
            type: 'line',
            data: []
          }
        ],
        chartOptions: {
          noData: {
            text: this.$t('t-loading-message'),
            style: {
              fontSize: '18px',
              color: 'grey',
            }
          },
          legend: {
            show: false
          },
          chart: {
            height: 200,
            type: 'line',
            stacked: false,
            curve: 'smooth',
            lineCap: 'butt',
            color: ['#377B2B'],
            toolbar: {
              show: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },
          xaxis: {
            // tickAmount: 3,
            labels: {
              style: {
                fontSize: '8',
              },
              rotate: 0,
            },
            categories: [],
          },
          yaxis: [
            {
              showAlways: false,
              seriesName: this.$t('t-monthly-income'),
              axisTicks: {
                show: false,
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  fontSize: '9',
                },
                formatter: (value) => { return parseFloat(value).toFixed(2) + " $" },
              },
              tooltip: {
                enabled: false
              }
            },
            {
              showAlways: false,
              seriesName: this.$t('t-accumulated-income'),
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: '#00E396'
              },
              labels: {
                style: {
                  fontSize: '9',
                },
                formatter: (value) => { return parseFloat(value).toFixed(2) + " $" },
              },
            },
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft',
              offsetY: 30,
              offsetX: 60
            },
          },
        }
      },
      report: null
    };
  },
  computed: {
    ...layoutComputed,
    result() {
      if (!this.report) {
        return null;
      }
      return parseFloat(this.report.baseValue).toFixed(2) + " $";
    },
  },
  async beforeMount() {
    let result = await this.fetchStatistics();
    let todayProfit = [];
    let accumProfit = [];
    let categoriesDates = [];

    result.forEach(function(value){
      todayProfit.push(parseFloat(value.profit).toFixed(2));
      accumProfit.push(parseFloat(value.cum_profit).toFixed(2));
      categoriesDates.push(moment(value.date).format('YY MMM'));
    });

    this.profitChart.chartOptions = {
      ...this.profitChart.chartOptions,
      xaxis : {
        categories: categoriesDates,
      }
    };

    this.profitChart.series[0].data = todayProfit;
    this.profitChart.series[1].data = accumProfit;
  },

  methods: {
    async fetchStatistics() {
        let statisticsForMonths = 12;
        let today = moment(new Date());
        let endDate = today.format("YYYY-MM-DD");
        let startDate = today.subtract(statisticsForMonths - 1, 'months').format("YYYY-MM-DD");

        return (await ProfitService.monthlyUserStatisticsForPeriod(startDate, endDate)).data;
    },
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center bg-soft-info bg-opacity-10" style="padding: 0.5rem 1rem;">
      <div class="col-lg-12">
        <h4 class="card-title" style="padding:0; margin:0;">{{ $t("t-last-12-month-statistics") }}</h4>
      </div>
    </div>
    <div class="card-body" style="padding: 0; margin-left:-10px; margin-right: -20px;">
        <apexchart
            class="apex-charts"
            height="200"
            dir="ltr"
            :series="profitChart.series"
            :options="profitChart.chartOptions"
        ></apexchart>
    </div>
  </div>
</template>
