<template>
  <div class="col col-lg" style="border: none;">
    <a href="/deals">
      <div class="d-flex flex-sm-row flex-column">
        <div class="py-1 text-uppercase fs-12">
          <span class="text-muted me-1">
          {{ $t("t-overall-profit") }}:
          </span>
        </div>
        <div class="py-0 py-sm-1">
          <span style="font-weight: 500;">
             {{this.result}}
              <i v-show="this.result == null" class="spinner-border spinner-border-sm text-danger"></i>
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import ProfitService from "@/services/profit-service";

export default {
  data() {
    return {
      report: null
    };
  },
  computed: {
    result() {
      if (!this.report) {
        return null;
      }
      return parseFloat(this.report.baseValue).toFixed(2) + " $";
    },
  },

  async mounted() {
    this.report = (await ProfitService.overallProfit()).data
  },
};
</script>
