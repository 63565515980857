import apiClient from "./http-common";

class ReportService {
  todayProfit() {
    return apiClient.get("/report/today-profit");
  }
  overallProfit() {
    return apiClient.get("/report/overall-profit");
  }
  monthlyProfit() {
    return apiClient.get("/report/monthly-profit");
  }
  userStatisticsForPeriod(startDate, endDate) {
    return apiClient.get("/user-statistics/user-profit?start_date="+startDate+"&end_date="+endDate);
  }
  monthlyUserStatisticsForPeriod(startDate, endDate) {
    return apiClient.get("/user-statistics/user-profit?start_date="+startDate+"&end_date="+endDate+'&group_by=month');
  }
}

export default new ReportService();
